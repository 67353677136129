<!-- @format -->

<template>
  <div>
    <div v-if="loader">
      <div
        role="status"
        class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center px-4 py-4 border mb-5 bg-white"
      >
        <div
          class="flex items-center justify-center bg-gray-300 rounded dark:bg-gray-500"
        >
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
        <div class="flex items-center justify-between w-full">
          <div>
            <div
              class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
            ></div>

            <div
              class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-500"
            ></div>
          </div>
          <div class="pl-52 pb-3">
            <div
              class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-80 my-2"
            ></div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <div class="mt-10">
          <div class="mt-10 bg-white">
            <div
              role="status"
              class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center px-4 py-4 border"
              v-for="key in 2"
              :key="key"
            >
              <div
                class="flex items-center justify-center bg-gray-300 rounded dark:bg-gray-500"
              >
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path
                    d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
                  />
                </svg>
              </div>
              <div class="flex items-center justify-between w-full">
                <div>
                  <div
                    class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
                  ></div>

                  <div
                    class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-500"
                  ></div>
                </div>
                <div class="pl-52 pb-3">
                  <div
                    class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-32 my-2"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="bg-white rounded-md">
        <div class="py-4 px-6 flex w-full gap-2">
          <div class="material-icons text-4xl w-10 text-blue-500">
            <img
              class="w-full"
              src="../../../../assets/icons/compliance_File.svg"
            />
          </div>
          <div class="w-full">
            <div class="text-lg font-medium line-clamp capitalize">
              {{ singleComplianceByPractice?.name }}
            </div>
            <div class="flex pt-2 gap-3 align-center">
              <div class="flex gap-2 font-light items-center capitalize">
                <span class="material-icons w-5 text-teal"
                  >format_list_bulleted_icon</span
                >
                {{ singleComplianceByPractice?.sub_type }}
              </div>
              <div class="flex font-light text-sm items-center">
                <img
                  class="w-6 pr-1"
                  src="../../../../assets/icons/calendar_clock.svg"
                />
                {{
                  singleComplianceByPractice?.due_date
                    ? moment(singleComplianceByPractice?.due_date).format(
                        "MMM DD, YYYY"
                      )
                    : moment(singleComplianceByPractice?.end_date).format(
                        "MMM DD, YYYY"
                      )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="p-8 bg-white border rounded-lg mt-8">
          <div>
            <div class="text-lg">{{ practiceName?.practice_name }}</div>
            <small class="text-gray-500">Previous Policies</small>
          </div>
          <div>
            <div
              class="w-full border border-gray flex justify-between items-center px-6 py-4 my-4 rounded-md"
              :class="key === 0 ? 'bg-teal bg-opacity-10' : ''"
              v-for="(
                item, key
              ) in singleComplianceByPractice?.submitted_compliance_item_files"
              :key="item.id"
            >
              <div class="gap-4">
                <div class="flex gap-4 items-center justify-between w-full">
                  <div class="flex gap-2">
                    <div class="w-8">
                      <img
                        class="w-full"
                        src="../../../../assets/icons/compliance_File.svg"
                      />
                    </div>
                    <div class="text-md line-clamp-2 capitalize">
                      {{ item.file_name }}
                    </div>
                  </div>
                </div>
                <div class="px-10">
                  <div class="flex-col">
                    <div class="flex gap-3 align-center">
                      <div
                        class="flex gap-2 font-light text-sm items-center capitalize"
                      >
                        <span class="material-icons w-5 text-teal"
                          >event_available_outlined</span
                        >
                        {{
                          item?.created_at
                            ? moment(item?.created_at).format("MMM DD, YYYY")
                            : moment(date).format("MMM DD, YYYY")
                        }}
                        <span class="material-icons w-5 text-teal"
                          >history_toggle_off_oulined</span
                        >
                        {{
                          moment(
                            item?.created_at?.split("T")[0] +
                              "T" +
                              item?.created_at?.split("T")[1]?.split(".")[0]
                          ).format("hh:mm A")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-1/4 flex items-center justify-end cursor-pointer"
                @click="downloadFile(item?.file, item?.file_name)"
              >
                <button class="text-teal font-medium">Download</button>
                <span class="material-icons w-5 text-teal px-1"
                  >save_alt_icon</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRoute } from "vue-router";
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import useDownloadFile from "@/composables/useDownloadFile";
const { downloadFile } = useDownloadFile();

const route = useRoute();
const store = useStore();
const complianceId = route.params.complianceId;
const practiceId = route.params.practiceId;
const loader = ref(false);

const practiceName = computed(() => {
  return singleComplianceByPractice.value?.practices?.find((practice) => {
    return practice.id == practiceId;
  });
});

const singleComplianceByPractice = computed(() => {
  return store.getters["hqComplianceCalender/getSubmittedFilesByPractice"];
});

onMounted(async () => {
  loader.value = true;
  store
    .dispatch("hqComplianceCalender/fetchSingleComplianceByPractice", {
      practiceId: practiceId,
      complianceId: complianceId,
    })
    .then(() => {
      loader.value = false;
    });
});
</script>
